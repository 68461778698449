import React, { useState, useEffect } from "react";
import {
  LineChart,
  Line,
  BarChart,
  Bar,
  AreaChart,
  Area,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
} from "recharts";
import { Colors } from "../../Colors";
import { BASE_URL } from "../../Service/service";

const MonthlyReport = ({ data }) => {
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [reportData, setReportData] = useState([]);
  const [totalAmount, setTotalAmount] = useState(0);
  const [chartType, setChartType] = useState("bar");
  const [filteredOrders, setFilteredOrders] = useState([]);
  const [orderDetails, setOrderDetails] = useState({});
  const [totalOrderAmount, setTotalOrderAmount] = useState(0);
  const [totalDeliveryCharge, setTotalDeliveryCharge] = useState(0);

  const fetchOrderDetails = async (orderId) => {
    try {
      const response = await fetch(`${BASE_URL}/order/details/${orderId}`);
      const data = await response.json();
      return data;
    } catch (error) {
      console.error(`Error fetching details for order ${orderId}:`, error);
      return null;
    }
  };

  // Function to fetch details for all orders
  const fetchAllOrderDetails = async (orders) => {
    const detailsPromises = orders.map((order) =>
      fetchOrderDetails(order.orderid)
    );
    const details = await Promise.all(detailsPromises);

    // Create a map of orderid to details
    const detailsMap = {};
    details.forEach((detail, index) => {
      if (detail && detail.success) {
        detailsMap[orders[index].orderid] = detail;
      }
    });

    setOrderDetails(detailsMap);
  };

  const getMinDate = () => {
    const currentYear = new Date().getFullYear();
    return `${currentYear}-01-01`;
  };

  const handleStartDateChange = (e) => {
    const selectedDate = e.target.value;
    const minDate = getMinDate();
    if (selectedDate >= minDate) {
      setStartDate(selectedDate);
    }
  };

  const handleEndDateChange = (e) => {
    const selectedDate = e.target.value;
    const minDate = getMinDate();
    if (selectedDate >= minDate && (!startDate || selectedDate >= startDate)) {
      setEndDate(selectedDate);
    }
  };

  useEffect(() => {
    if (startDate && endDate) {
      const startDateTime = new Date(startDate);
      startDateTime.setHours(0, 0, 0, 0);

      const endDateTime = new Date(endDate);
      endDateTime.setHours(23, 59, 59, 999);

      // Filter completed orders
      const completedOrders = data.filter(
        (order) => order.payment_status === 4 || order.payment_status === "4"
      );

      // Filter by date range
      const filtered = completedOrders.filter((order) => {
        const orderDate = new Date(order.subscription_details.currentTime);
        return orderDate >= startDateTime && orderDate <= endDateTime;
      });

      // Process each order to ensure proper number handling
      const processedOrders = filtered.map((order) => {
        // Parse all numeric values with proper fallbacks
        const subtotal = parseFloat(order.subtotal) || 0;
        const deliveryCharge = parseFloat(order.deliverycharge) || 0;
        const discount = parseFloat(order.discount) || 0;

        // Calculate total correctly
        const total = subtotal + deliveryCharge - discount;

        return {
          ...order,
          subtotal,
          deliverycharge: deliveryCharge,
          discount,
          subscription_details: {
            ...order.subscription_details,
            totalPricePaid: total.toFixed(2),
          },
        };
      });
      
      // Calculate totals for Order Amount (minus discount) and Delivery Charge
      const orderAmountTotal = processedOrders.reduce(
        (sum, order) => sum + order.subtotal - order.discount,
        0
      );
      
      const deliveryChargeTotal = processedOrders.reduce(
        (sum, order) => sum + order.deliverycharge,
        0
      );
      
      setTotalOrderAmount(orderAmountTotal.toFixed(2));
      setTotalDeliveryCharge(deliveryChargeTotal.toFixed(2));
      
      fetchAllOrderDetails(processedOrders);
      setFilteredOrders(processedOrders);

      // Group data by date with corrected calculations
      const groupedData = processedOrders.reduce((acc, order) => {
        const date = new Date(order.subscription_details.currentTime)
          .toISOString()
          .split("T")[0];

        if (!acc[date]) {
          acc[date] = {
            date,
            totalAmount: 0,
            count: 0,
            deliveryCharge: 0,
            subtotal: 0,
            discount: 0,
          };
        }

        acc[date].totalAmount += parseFloat(
          order.subscription_details.totalPricePaid
        );
        acc[date].count += 1;
        acc[date].deliveryCharge += parseFloat(order.deliverycharge) || 0;
        acc[date].discount += parseFloat(order.discount) || 0;
        acc[date].subtotal += parseFloat(order.subtotal) || 0;

        return acc;
      }, {});

      // Fill missing dates
      const allDates = [];
      const currentDate = new Date(startDateTime);
      while (currentDate <= endDateTime) {
        const dateStr = currentDate.toISOString().split("T")[0];
        if (!groupedData[dateStr]) {
          groupedData[dateStr] = {
            date: dateStr,
            totalAmount: 0,
            count: 0,
            deliveryCharge: 0,
            subtotal: 0,
            discount: 0,
          };
        }
        allDates.push(dateStr);
        currentDate.setDate(currentDate.getDate() + 1);
      }

      const sortedData = allDates.map((date) => ({
        ...groupedData[date],
        date: formatDateForDisplay(date),
      }));

      setReportData(sortedData);

      // Calculate total amount correctly
      const total = processedOrders.reduce(
        (sum, order) =>
          sum + parseFloat(order.subscription_details.totalPricePaid),
        0
      );

      setTotalAmount(total.toFixed(2));
    }
  }, [startDate, endDate, data]);

  const formatDateForDisplay = (dateString) => {
    const date = new Date(dateString);
    const day = String(date.getDate()).padStart(2, "0");
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const year = date.getFullYear();
    return `${day}/${month}/${year}`;
  };

  const formatDateTime = (dateTimeString) => {
    const date = new Date(dateTimeString);
    const day = String(date.getDate()).padStart(2, "0");
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const year = date.getFullYear();
    let hours = date.getHours();
    const minutes = String(date.getMinutes()).padStart(2, "0");
    const seconds = String(date.getSeconds()).padStart(2, "0");
    const ampm = hours >= 12 ? "PM" : "AM";
    hours = hours % 12;
    hours = hours ? hours : 12;
    return `${day}/${month}/${year} ${hours}:${minutes}:${seconds} ${ampm}`;
  };

  const handlePrint = () => {
    window.print();
  };

  const getCurrentDate = () => {
    const now = new Date();
    return now.toISOString().split("T")[0];
  };

  const renderChart = () => {
    const ChartComponent =
      chartType === "bar"
        ? BarChart
        : chartType === "area"
        ? AreaChart
        : LineChart;
    const DataComponent =
      chartType === "bar" ? Bar : chartType === "area" ? Area : Line;

    return (
      <ResponsiveContainer width="100%" height={300}>
        <ChartComponent data={reportData}>
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis dataKey="date" />
          <YAxis yAxisId="left" orientation="left" stroke="#8884d8" />
          <YAxis yAxisId="right" orientation="right" stroke="#82ca9d" />
          <Tooltip />
          <Legend />
          <DataComponent
            yAxisId="left"
            type="monotone"
            dataKey="totalAmount"
            stroke="#8884d8"
            fill="#8884d8"
            name="Daily Revenue"
          />
          <DataComponent
            yAxisId="right"
            type="monotone"
            dataKey="count"
            stroke="#82ca9d"
            fill="#82ca9d"
            name="Order Count"
          />
        </ChartComponent>
      </ResponsiveContainer>
    );
  };

  return (
    <div className="monthly-report" style={styles.container}>
      <h2 style={styles.title}>Date Range Report</h2>
      <div style={styles.controls}>
        <div style={styles.dateInputs}>
          <label style={styles.label}>
            Start Date:
            <input
              type="date"
              value={startDate}
              onChange={handleStartDateChange}
              min={getMinDate()}
              style={styles.input}
            />
          </label>
          <label style={styles.label}>
            End Date:
            <input
              type="date"
              value={endDate}
              onChange={handleEndDateChange}
              min={startDate}
              max={getCurrentDate()}
              style={styles.input}
            />
          </label>
        </div>
        <div style={styles.chartControls}>
          <select
            value={chartType}
            onChange={(e) => setChartType(e.target.value)}
            style={styles.select}
          >
            <option value="line">Line Chart</option>
            <option value="bar">Bar Chart</option>
            <option value="area">Area Chart</option>
          </select>
          <button onClick={handlePrint} style={styles.button}>
            Print Report
          </button>
        </div>
      </div>
      {startDate && endDate && reportData.length > 0 && (
        <div style={styles.reportContent}>
          <h3 style={styles.subtitle}>
            Report from {formatDateForDisplay(startDate)} to{" "}
            {formatDateForDisplay(endDate)}
          </h3>
          <p style={styles.totalRevenue}>Total Revenue: ₹{totalAmount}</p>
          <div style={styles.chartContainer} className="no-print-chart">
            {renderChart()}
          </div>
          <div style={styles.tableContainer}>
            <table style={styles.table}>
              <thead>
                <tr>
                  <th style={styles.tableHeader}>No</th>
                  <th style={styles.tableHeader}>Date & Time</th>
                  <th style={styles.tableHeader}>Customer Name</th>
                  <th style={styles.tableHeader}>Order ID</th>
                  <th style={styles.tableHeader}>Start Date</th>
                  <th style={styles.tableHeader}>End Date</th>
                  <th style={styles.tableHeader}>Payment Method</th>
                  <th style={styles.tableHeader}>Order Total (Incl.GST 5%)</th>
                  <th style={styles.tableHeader}>Delivery Charge (Incl.GST 18%)</th>
                  <th style={styles.tableHeader}>Total ₹</th>
                  <th style={styles.tableHeader}>Razorpay Fee (2%)</th>
                </tr>
              </thead>
              <tbody>
                {filteredOrders.map((order, index) => {
                  const razorpayFee =
                    orderDetails[order.orderid]?.fee_details?.fee || 0;
                  const total = parseFloat(
                    order.subscription_details.totalPricePaid
                  );

                  return (
                    <tr key={order.orderid || index}>
                      <td style={styles.tableCell}>{index + 1}</td>
                      <td style={styles.tableCell}>
                        {formatDateTime(order.subscription_details.currentTime)}
                      </td>
                      <td style={styles.tableCell}>{order.userdata.first_name}</td>
                      <td style={styles.tableCell}>{order.orderid}</td>
                      <td style={styles.tableCell}>{order.subscription_details.startDate}</td>
                      <td style={styles.tableCell}>{order.subscription_details.endDate}</td>
                      <td style={styles.tableCell}>
                        {orderDetails[order.orderid]?.payment_details
                          ?.payment_method || "-"}
                      </td>
                      <td style={styles.tableCell}>₹{parseFloat(order.subtotal).toFixed(2)}</td>
                      <td style={styles.tableCell}>₹{parseFloat(order.deliverycharge).toFixed(2)}</td>
                      <td style={styles.tableCell}>₹{total.toFixed(2)}</td>
                      <td style={styles.tableCell}>₹{razorpayFee.toFixed(2)}</td>
                    </tr>
                  );
                })}
                <tr>
                  <td
                    colSpan="7"
                    style={styles.tableFooter}
                  >
                    Total:
                  </td>
                  <td style={styles.tableFooter}>₹{totalOrderAmount}</td>
                  <td style={styles.tableFooter}>₹{totalDeliveryCharge}</td>
                  <td style={styles.tableFooter}>₹{totalAmount}</td>
                  <td style={styles.tableFooter}>
                    ₹
                    {filteredOrders
                      .reduce(
                        (sum, order) =>
                          sum +
                          (orderDetails[order.orderid]?.fee_details?.total_fee ||
                            0),
                        0
                      )
                      .toFixed(2)}
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      )}
      <style>
        {`
          @media print {
            body {
              font-size: 10pt;
              margin: 0;
              padding: 0 !important;
              min-width: 100%;
              width: 100%;
            }
            
            .monthly-report {
              padding: 10px !important;
              width: 100% !important;
              background-color: white !important;
              font-size: 10pt !important;
            }
            
            h2, h3 {
              font-size: 14pt !important;
              margin-bottom: 10px !important;
            }
            
            p {
              font-size: 12pt !important;
              margin-bottom: 10px !important;
            }
            
            .controls, .no-print-chart {
              display: none !important;
            }
            
            table {
              width: 100% !important;
              font-size: 9pt !important;
              border-collapse: collapse !important;
              table-layout: fixed !important;
            }
            
            th, td {
              padding: 3px 5px !important;
              border: 0.5pt solid #000 !important;
              overflow: hidden !important;
              text-overflow: ellipsis !important;
              white-space: normal !important;
              word-break: break-word !important;
            }
            
            th {
              background-color: #f0f0f0 !important;
              font-weight: bold !important;
              font-size: 9pt !important;
            }
            
            tr:nth-child(even) {
              background-color: #f9f9f9 !important;
            }
            
            tr:last-child {
              font-weight: bold !important;
              background-color: #f0f0f0 !important;
            }
            
            .tableContainer {
              overflow: visible !important;
              width: 100% !important;
            }
          }
        `}
      </style>
    </div>
  );
};

const styles = {
  container: {
    padding: "20px",
    backgroundColor: "#f5f5f5",
    borderRadius: "8px",
    marginBottom: "20px",
  },
  title: {
    fontSize: "24px",
    fontWeight: "bold",
    marginBottom: "20px",
  },
  controls: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    marginBottom: "20px",
    flexWrap: "wrap",
  },
  dateInputs: {
    display: "flex",
    gap: "10px",
    marginBottom: "10px",
  },
  chartControls: {
    display: "flex",
    gap: "10px",
    alignItems: "center",
  },
  label: {
    display: "flex",
    flexDirection: "column",
    fontSize: "14px",
  },
  input: {
    padding: "8px",
    fontSize: "16px",
    borderRadius: "4px",
    border: "1px solid #ccc",
    marginTop: "4px",
  },
  select: {
    padding: "8px",
    fontSize: "16px",
    borderRadius: "4px",
    border: "1px solid #ccc",
  },
  button: {
    padding: "8px 16px",
    fontSize: "16px",
    background: Colors.LinearGradient,
    color: "black",
    border: "none",
    borderRadius: "4px",
    cursor: "pointer",
  },
  reportContent: {
    backgroundColor: "white",
    padding: "20px",
    borderRadius: "8px",
  },
  subtitle: {
    fontSize: "20px",
    fontWeight: "bold",
    marginBottom: "10px",
  },
  totalRevenue: {
    fontSize: "18px",
    marginBottom: "20px",
  },
  chartContainer: {
    marginTop: "20px",
    marginBottom: "20px",
  },
  tableContainer: {
    width: "100%",
    overflowX: "auto",
  },
  table: {
    width: "100%",
    borderCollapse: "collapse",
    marginTop: "20px",
    fontSize: "14px",
  },
  tableHeader: {
    border: "1px solid #dddddd",
    padding: "8px",
    backgroundColor: "#f2f2f2",
    textAlign: "left",
    fontWeight: "bold",
    whiteSpace: "nowrap",
  },
  tableCell: {
    border: "1px solid #dddddd",
    padding: "6px 8px",
    textAlign: "left",
    whiteSpace: "nowrap",
  },
  tableFooter: {
    border: "1px solid #dddddd",
    padding: "8px",
    backgroundColor: "#f2f2f2",
    fontWeight: "bold",
    textAlign: "right",
  }
};

export default MonthlyReport;





// import React, { useState, useEffect } from "react";
// import {
//   LineChart,
//   Line,
//   BarChart,
//   Bar,
//   AreaChart,
//   Area,
//   XAxis,
//   YAxis,
//   CartesianGrid,
//   Tooltip,
//   Legend,
//   ResponsiveContainer,
// } from "recharts";
// import { Colors } from "../../Colors";
// import { BASE_URL } from "../../Service/service";

// const MonthlyReport = ({ data }) => {
//   const [startDate, setStartDate] = useState("");
//   const [endDate, setEndDate] = useState("");
//   const [reportData, setReportData] = useState([]);
//   const [totalAmount, setTotalAmount] = useState(0);
//   const [chartType, setChartType] = useState("bar");
//   const [filteredOrders, setFilteredOrders] = useState([]);
//   const [orderDetails, setOrderDetails] = useState({});

//   const fetchOrderDetails = async (orderId) => {
//     try {
//       const response = await fetch(`${BASE_URL}/order/details/${orderId}`);
//       const data = await response.json();
//       return data;
//     } catch (error) {
//       console.error(`Error fetching details for order ${orderId}:`, error);
//       return null;
//     }
//   };

//   // Function to fetch details for all orders
//   const fetchAllOrderDetails = async (orders) => {
//     const detailsPromises = orders.map((order) =>
//       fetchOrderDetails(order.orderid)
//     );
//     const details = await Promise.all(detailsPromises);

//     // Create a map of orderid to details
//     const detailsMap = {};
//     details.forEach((detail, index) => {
//       if (detail && detail.success) {
//         detailsMap[orders[index].orderid] = detail;
//       }
//     });

//     setOrderDetails(detailsMap);
//   };

//   const getMinDate = () => {
//     const currentYear = new Date().getFullYear();
//     return `${currentYear}-01-01`;
//   };

//   const handleStartDateChange = (e) => {
//     const selectedDate = e.target.value;
//     const minDate = getMinDate();
//     if (selectedDate >= minDate) {
//       setStartDate(selectedDate);
//     }
//   };

//   const handleEndDateChange = (e) => {
//     const selectedDate = e.target.value;
//     const minDate = getMinDate();
//     if (selectedDate >= minDate && (!startDate || selectedDate >= startDate)) {
//       setEndDate(selectedDate);
//     }
//   };

//   useEffect(() => {
//     if (startDate && endDate) {
//       const startDateTime = new Date(startDate);
//       startDateTime.setHours(0, 0, 0, 0);

//       const endDateTime = new Date(endDate);
//       endDateTime.setHours(23, 59, 59, 999);

//       // Filter completed orders
//       const completedOrders = data.filter(
//         (order) => order.payment_status === 4 || order.payment_status === "4"
//       );

//       // Filter by date range
//       const filtered = completedOrders.filter((order) => {
//         const orderDate = new Date(order.subscription_details.currentTime);
//         return orderDate >= startDateTime && orderDate <= endDateTime;
//       });

//       // In the useEffect where you process orders
//       const processedOrders = filtered.map((order) => {
//         // Parse all numeric values with proper fallbacks
//         const subtotal = parseFloat(order.subtotal) || 0;
//         const deliveryCharge = parseFloat(order.deliverycharge) || 0;
//         const discount = parseFloat(order.discount) || 0;

//         // Calculate 5% GST on subtotal
//         const gst = 0.05;

//         // Calculate order total correctly: subtotal - discount + 5% GST
//         const orderdiscount = subtotal - discount;
//         const orderTotal = orderdiscount * (1 + gst);

//         // User paid amount is the totalPricePaid from subscription_details
//         const totalPaid =
//           parseFloat(order.subscription_details.totalPricePaid) || 0;

//         return {
//           ...order,
//           subtotal,
//           deliverycharge: deliveryCharge,
//           discount,
//           gst,
//           orderTotal,
//           subscription_details: {
//             ...order.subscription_details,
//             totalPricePaid: totalPaid.toFixed(2),
//           },
//         };
//       });
//       fetchAllOrderDetails(processedOrders);
//       setFilteredOrders(processedOrders);

//       // Group data by date with corrected calculations
//       const groupedData = processedOrders.reduce((acc, order) => {
//         const date = new Date(order.subscription_details.currentTime)
//           .toISOString()
//           .split("T")[0];

//         if (!acc[date]) {
//           acc[date] = {
//             date,
//             totalAmount: 0,
//             count: 0,
//             deliveryCharge: 0,
//             subtotal: 0,
//             discount: 0,
//           };
//         }

//         acc[date].totalAmount += parseFloat(
//           order.subscription_details.totalPricePaid
//         );
//         acc[date].count += 1;
//         acc[date].deliveryCharge += parseFloat(order.deliverycharge) || 0;
//         acc[date].discount += parseFloat(order.discount) || 0;
//         acc[date].subtotal += parseFloat(order.subtotal) || 0;

//         return acc;
//       }, {});

//       // Fill missing dates
//       const allDates = [];
//       const currentDate = new Date(startDateTime);
//       while (currentDate <= endDateTime) {
//         const dateStr = currentDate.toISOString().split("T")[0];
//         if (!groupedData[dateStr]) {
//           groupedData[dateStr] = {
//             date: dateStr,
//             totalAmount: 0,
//             count: 0,
//             deliveryCharge: 0,
//             subtotal: 0,
//             discount: 0,
//           };
//         }
//         allDates.push(dateStr);
//         currentDate.setDate(currentDate.getDate() + 1);
//       }

//       const sortedData = allDates.map((date) => ({
//         ...groupedData[date],
//         date: formatDateForDisplay(date),
//       }));

//       setReportData(sortedData);

//       // Calculate total amount correctly
//       const total = processedOrders.reduce(
//         (sum, order) =>
//           sum + parseFloat(order.subscription_details.totalPricePaid),
//         0
//       );

//       setTotalAmount(total.toFixed(2));
//     }
//   }, [startDate, endDate, data]);

//   // ... rest of the component remains the same ...
//   const formatDateForDisplay = (dateString) => {
//     const date = new Date(dateString);
//     const day = String(date.getDate()).padStart(2, "0");
//     const month = String(date.getMonth() + 1).padStart(2, "0");
//     const year = date.getFullYear();
//     return `${day}/${month}/${year}`;
//   };

//   const formatDateTime = (dateTimeString) => {
//     const date = new Date(dateTimeString);
//     const day = String(date.getDate()).padStart(2, "0");
//     const month = String(date.getMonth() + 1).padStart(2, "0");
//     const year = date.getFullYear();
//     let hours = date.getHours();
//     const minutes = String(date.getMinutes()).padStart(2, "0");
//     const seconds = String(date.getSeconds()).padStart(2, "0");
//     const ampm = hours >= 12 ? "PM" : "AM";
//     hours = hours % 12;
//     hours = hours ? hours : 12;
//     return `${day}/${month}/${year} ${hours}:${minutes}:${seconds} ${ampm}`;
//   };

//   const handlePrint = () => {
//     window.print();
//   };

//   const getCurrentDate = () => {
//     const now = new Date();
//     return now.toISOString().split("T")[0];
//   };

//   const renderChart = () => {
//     const ChartComponent =
//       chartType === "bar"
//         ? BarChart
//         : chartType === "area"
//         ? AreaChart
//         : LineChart;
//     const DataComponent =
//       chartType === "bar" ? Bar : chartType === "area" ? Area : Line;

//     return (
//       <ResponsiveContainer width="100%" height={300}>
//         <ChartComponent data={reportData}>
//           <CartesianGrid strokeDasharray="3 3" />
//           <XAxis dataKey="date" />
//           <YAxis yAxisId="left" orientation="left" stroke="#8884d8" />
//           <YAxis yAxisId="right" orientation="right" stroke="#82ca9d" />
//           <Tooltip />
//           <Legend />
//           <DataComponent
//             yAxisId="left"
//             type="monotone"
//             dataKey="totalAmount"
//             stroke="#8884d8"
//             fill="#8884d8"
//             name="Daily Revenue"
//           />
//           <DataComponent
//             yAxisId="right"
//             type="monotone"
//             dataKey="count"
//             stroke="#82ca9d"
//             fill="#82ca9d"
//             name="Order Count"
//           />
//         </ChartComponent>
//       </ResponsiveContainer>
//     );
//   };

//   return (
//     <div className="monthly-report" style={styles.container}>
//       <h2 style={styles.title}>Date Range Report</h2>
//       <div style={styles.controls}>
//         <div style={styles.dateInputs}>
//           <label style={styles.label}>
//             Start Date:
//             <input
//               type="date"
//               value={startDate}
//               onChange={handleStartDateChange}
//               min={getMinDate()}
//               style={styles.input}
//             />
//           </label>
//           <label style={styles.label}>
//             End Date:
//             <input
//               type="date"
//               value={endDate}
//               onChange={handleEndDateChange}
//               min={startDate}
//               max={getCurrentDate()}
//               style={styles.input}
//             />
//           </label>
//         </div>
//         <div style={styles.chartControls}>
//           <select
//             value={chartType}
//             onChange={(e) => setChartType(e.target.value)}
//             style={styles.select}
//           >
//             <option value="line">Line Chart</option>
//             <option value="bar">Bar Chart</option>
//             <option value="area">Area Chart</option>
//           </select>
//           <button onClick={handlePrint} style={styles.button}>
//             Print Report
//           </button>
//         </div>
//       </div>
//       {startDate && endDate && reportData.length > 0 && (
//         <div style={styles.reportContent}>
//           <h3 style={styles.subtitle}>
//             Report from {formatDateForDisplay(startDate)} to{" "}
//             {formatDateForDisplay(endDate)}
//           </h3>
//           <p style={styles.totalRevenue}>Total Revenue: ₹{totalAmount}</p>
//           <div style={styles.chartContainer}>{renderChart()}</div>
//           <table>
//             <thead>
//               <tr>
//                 <th>No</th>
//                 <th>Date & Time</th>
//                 <th>User Name</th>
//                 <th>Invoice No</th>
//                 <th>Start Date</th>
//                 <th>End Date</th>
//                 <th>Payment Method</th>
//                 <th>Order Total (Incl.gst 5%)</th>
//                 <th>Delivery Charge (Incl.gst 18%)</th>
//                 {/* <th>Discount</th> */}
//                 <th>User Paid Amount</th>
//                 <th>Razorpay Fee</th>
//                 <th>Profit</th>
//               </tr>
//             </thead>
//             <tbody>
//               {filteredOrders.map((order, index) => {
//                 const razorpayFee =
//                   orderDetails[order.orderid]?.fee_details?.total_fee || 0;
//                 const totalPaid = parseFloat(
//                   order.subscription_details.totalPricePaid
//                 );
//                 const profit = totalPaid - razorpayFee;

//                 return (
//                   <tr key={order.orderid || index}>
//                     <td>{index+1}</td>
//                     <td>
//                       {formatDateTime(order.subscription_details.currentTime)}
//                     </td>
//                     <td>{order.userdata.first_name}</td>
//                     <td>{order.invoice_no}</td>
//                     <td>{order.subscription_details.startDate}</td>
//                     <td>{order.subscription_details.endDate}</td>
//                     <td>
//                       {orderDetails[order.orderid]?.payment_details
//                         ?.payment_method || "-"}
//                     </td>
//                     <td>₹{order.orderTotal.toFixed(2)}</td>{" "}
//                     {/* Order Total: subtotal - discount + 5% GST */}
//                     <td>₹{parseFloat(order.deliverycharge).toFixed(2)}</td>
//                     {/* <td>₹{parseFloat(order.discount).toFixed(2)}</td> */}
//                     <td>₹{totalPaid.toFixed(2)}</td>{" "}
//                     {/* User Paid Amount: totalPricePaid */}
//                     <td>₹{razorpayFee.toFixed(2)}</td>
//                     <td>₹{profit.toFixed(2)}</td>
//                   </tr>
//                 );
//               })}
//               {/* Update the footer row totals as well */}
//               <tr>
//                 <td
//                   colSpan="7"
//                   style={{ textAlign: "right", fontWeight: "bold" }}
//                 >
//                   Total:
//                 </td>
//                 <td style={{ fontWeight: "bold" }}>
//                   ₹
//                   {filteredOrders
//                     .reduce((sum, order) => sum + order.orderTotal, 0)
//                     .toFixed(2)}
//                 </td>
//                 <td style={{ fontWeight: "bold" }}>
//                   ₹
//                   {filteredOrders
//                     .reduce(
//                       (sum, order) => sum + parseFloat(order.deliverycharge),
//                       0
//                     )
//                     .toFixed(2)}
//                 </td>
//                 {/* <td style={{ fontWeight: "bold" }}>
//                   ₹
//                   {filteredOrders
//                     .reduce((sum, order) => sum + parseFloat(order.discount), 0)
//                     .toFixed(2)}
//                 </td> */}
//                 <td style={{ fontWeight: "bold" }}>₹{totalAmount}</td>
//                 <td style={{ fontWeight: "bold" }}>
//                   ₹
//                   {filteredOrders
//                     .reduce(
//                       (sum, order) =>
//                         sum +
//                         (orderDetails[order.orderid]?.fee_details?.total_fee ||
//                           0),
//                       0
//                     )
//                     .toFixed(2)}
//                 </td>
//                 <td style={{ fontWeight: "bold" }}>
//                   ₹
//                   {(
//                     totalAmount -
//                     filteredOrders.reduce(
//                       (sum, order) =>
//                         sum +
//                         (orderDetails[order.orderid]?.fee_details?.total_fee ||
//                           0),
//                       0
//                     )
//                   ).toFixed(2)}
//                 </td>
//               </tr>
//             </tbody>
//           </table>
//         </div>
//       )}
//     </div>
//   );
// };

// const styles = {
//   container: {
//     padding: "20px",
//     backgroundColor: "#f5f5f5",
//     borderRadius: "8px",
//     marginBottom: "20px",
//   },
//   title: {
//     fontSize: "24px",
//     fontWeight: "bold",
//     marginBottom: "20px",
//   },
//   controls: {
//     display: "flex",
//     justifyContent: "space-between",
//     alignItems: "center",
//     marginBottom: "20px",
//     flexWrap: "wrap",
//   },
//   dateInputs: {
//     display: "flex",
//     gap: "10px",
//     marginBottom: "10px",
//   },
//   chartControls: {
//     display: "flex",
//     gap: "10px",
//     alignItems: "center",
//   },
//   label: {
//     display: "flex",
//     flexDirection: "column",
//     fontSize: "14px",
//   },
//   input: {
//     padding: "8px",
//     fontSize: "16px",
//     borderRadius: "4px",
//     border: "1px solid #ccc",
//     marginTop: "4px",
//   },
//   select: {
//     padding: "8px",
//     fontSize: "16px",
//     borderRadius: "4px",
//     border: "1px solid #ccc",
//   },
//   button: {
//     padding: "8px 16px",
//     fontSize: "16px",
//     background: Colors.LinearGradient,
//     color: "black",
//     border: "none",
//     borderRadius: "4px",
//     cursor: "pointer",
//   },
//   reportContent: {
//     backgroundColor: "white",
//     padding: "20px",
//     borderRadius: "8px",
//   },
//   subtitle: {
//     fontSize: "20px",
//     fontWeight: "bold",
//     marginBottom: "10px",
//   },
//   totalRevenue: {
//     fontSize: "18px",
//     marginBottom: "20px",
//   },
//   chartContainer: {
//     marginTop: "20px",
//     marginBottom: "20px",
//   },
//   table: {
//     width: "100%",
//     borderCollapse: "collapse",
//     marginTop: "20px",
//   },
//   tableHeader: {
//     border: "1px solid #dddddd",
//     padding: "12px",
//     backgroundColor: Colors.deepBlue100,
//     color: "black",
//     textAlign: "left",
//   },
//   tableCell: {
//     border: "1px solid #dddddd",
//     padding: "8px",
//     textAlign: "left",
//   },
// };

// export default MonthlyReport;
