import React, { useState, useEffect } from "react";
import { BASE_URL } from "../../Service/service";
import { Colors } from "../../Colors";

const TransactionTable = () => {
  const [transactions, setTransactions] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 10;

  const styles = {
    container: {
      width: "100%",
      padding: "24px",
      minHeight: "100vh",
    },
    header: {
      background: Colors.LinearGradient,
      padding: "24px",
      borderRadius: "12px 12px 0 0",
      marginBottom: "0",
    },
    headerTitle: {
      color: Colors.grey16,
      fontSize: "24px",
      fontWeight: "600",
      textAlign: "center",
      margin: "0",
    },
    tableContainer: {
      backgroundColor: Colors.greyWhite,
      borderRadius: "6px",
      boxShadow: "0 2px 4px rgba(0, 0, 0, 0.05)",
      overflow: "hidden",
    },
    table: {
      width: "100%",
      borderCollapse: "separate",
      backgroundColor: Colors.greyWhite,
      borderSpacing: 0,
    },
    th: {
      background: Colors.LinearGradient,
      padding: "16px",
      textAlign: "left",
      fontSize: "14px",
      fontWeight: "600",
      color: Colors.grey28,
      borderBottom: `2px solid ${Colors.yellow50}`,
      borderRight: `1px solid ${Colors.yellow50}`,
      whiteSpace: "nowrap",
      "&:last-child": {
        borderRight: "none",
      },
    },
    td: {
      padding: "16px",
      borderBottom: `1px solid ${Colors.grey95}`,
      borderRight: `1px solid ${Colors.grey95}`,
      color: Colors.grey28,
      fontSize: "14px",
      "&:last-child": {
        borderRight: "none",
      },
    },
    indexCell: {
      width: "50px",
      textAlign: "center",
      fontWeight: "500",
      color: Colors.grey28,
      borderRight: `1px solid ${Colors.yellow50}`,
    },
    statusBadge: (status) => ({
      padding: "6px 12px",
      borderRadius: "12px",
      fontSize: "12px",
      fontWeight: "500",
      textTransform: "capitalize",
      backgroundColor:
        status === "captured"
          ? Colors.green1
          : status === "failed"
          ? Colors.pink1
          : status === "pending"
          ? Colors.yellow2
          : Colors.grey95,
      color:
        status === "captured"
          ? Colors.green200
          : status === "failed"
          ? Colors.pink200
          : status === "pending"
          ? Colors.yellow200
          : Colors.grey44,
    }),
    pagination: {
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
      padding: "20px",
      backgroundColor: Colors.greyWhite,
      borderTop: `1px solid ${Colors.grey95}`,
    },
    button: {
      padding: "10px 20px",
      background: Colors.LinearGradient,
      color: Colors.grey16,
      border: "none",
      borderRadius: "8px",
      cursor: "pointer",
      fontWeight: "500",
      transition: "opacity 0.2s ease",
      "&:hover": {
        opacity: 0.9,
      },
    },
    disabledButton: {
      padding: "10px 20px",
      backgroundColor: Colors.grey95,
      color: Colors.grey64,
      border: "none",
      borderRadius: "8px",
      cursor: "not-allowed",
    },
    pageInfo: {
      fontSize: "14px",
      color: Colors.grey44,
      fontWeight: "500",
    },
    loadingContainer: {
      minHeight: "100vh",
      padding: "40px",
      textAlign: "center",
      color: Colors.grey44,
      borderRadius: "12px",
    },
    errorContainer: {
      padding: "40px",
      textAlign: "center",
      color: Colors.pink200,
      backgroundColor: Colors.pink1,
      borderRadius: "12px",
    },
  };

  useEffect(() => {
    fetchTransactions();
  }, [currentPage]);

  const fetchTransactions = async () => {
    try {
      setLoading(true);
      const response = await fetch(
        `${BASE_URL}/transactions/all?count=${itemsPerPage}&skip=${
          (currentPage - 1) * itemsPerPage
        }`
      );
      const data = await response.json();
      if (data.success) {
        setTransactions(data.transactions);
      } else {
        setError("Failed to fetch transactions");
      }
    } catch (err) {
      setError("Error loading transactions");
    } finally {
      setLoading(false);
    }
  };

  const formatAmount = (amount, currency) => {
    return new Intl.NumberFormat("en-IN", {
      style: "currency",
      currency: currency || "INR",
    }).format(amount);
  };

  const formatDate = (dateString) => {
    return new Date(dateString).toLocaleDateString("en-IN", {
      day: "2-digit",
      month: "short",
      year: "numeric",
      hour: "2-digit",
      minute: "2-digit",
    });
  };

  const formatContact = (contact) => {
    return contact || "N/A";
  };

  if (loading) {
    return <div style={styles.loadingContainer}>Loading transactions...</div>;
  }

  if (error) {
    return <div style={styles.errorContainer}>{error}</div>;
  }

  return (
    <div style={styles.container}>
      <h1 style={{ margin: "32px", display: "flex", justifyContent: "center" }}>
        Razorpay Payments
      </h1>
      <div style={styles.tableContainer}>
        {/* <div style={styles.header}>
          <h1 style={styles.headerTitle}>Transaction History</h1>
        </div> */}
        <table style={styles.table}>
          <thead>
            <tr>
              <th style={{ ...styles.th, ...styles.indexCell }}>#</th>
              <th style={styles.th}>Transaction ID</th>
              <th style={styles.th}>Date</th>
              <th style={styles.th}>Contact</th>
              <th style={styles.th}>Fee</th>
              <th style={styles.th}>Tax</th>
              <th style={styles.th}>Total Fee</th>
              <th style={styles.th}>Payment Method</th>
              <th style={styles.th}>Amount</th>
              <th style={styles.th}>Status</th>
            </tr>
          </thead>
          <tbody>
            {transactions.map((transaction, index) => (
              <tr key={transaction.transaction_id}>
                <td style={{ ...styles.td, ...styles.indexCell }}>
                  {(currentPage - 1) * itemsPerPage + index + 1}
                </td>
                <td style={styles.td}>{transaction.transaction_id}</td>
                <td style={styles.td}>{formatDate(transaction.created_at)}</td>
                <td style={styles.td}>{formatContact(transaction.contact)}</td>
                <td style={styles.td}>
                  {formatAmount(transaction.fee, transaction.currency)}
                </td>
                <td style={styles.td}>
                  {formatAmount(transaction.tax, transaction.currency)}
                </td>
                <td style={styles.td}>
                {formatAmount(transaction.fee + transaction.tax, transaction.currency)}
                </td>
                <td style={styles.td}>
                  {transaction.payment_method?.toUpperCase() || "N/A"}
                </td>
                <td style={styles.td}>
                  {formatAmount(transaction.amount, transaction.currency)}
                </td>
                <td style={styles.td}>
                  <span style={styles.statusBadge(transaction.status)}>
                    {transaction.status?.toLowerCase() || "N/A"}
                  </span>
                </td>
              </tr>
            ))}
          </tbody>
        </table>

        <div style={styles.pagination}>
          <button
            style={currentPage === 1 ? styles.disabledButton : styles.button}
            onClick={() => setCurrentPage((prev) => Math.max(1, prev - 1))}
            disabled={currentPage === 1}
          >
            Previous
          </button>
          <span style={styles.pageInfo}>Page {currentPage}</span>
          <button
            style={
              transactions.length < itemsPerPage
                ? styles.disabledButton
                : styles.button
            }
            onClick={() => setCurrentPage((prev) => prev + 1)}
            disabled={transactions.length < itemsPerPage}
          >
            Next
          </button>
        </div>
      </div>
    </div>
  );
};

export default TransactionTable;
