import React, { useEffect, useState } from "react";
import axios from "axios";
import "./PaymentScreen.css";
import { BASE_URL } from "../../Service/service";
import { Colors } from "../../Colors";
import MonthlyReport from "./MonthlyReport";
import AddonContainerPayment from "./AddonContainerPayment";
import LalasKitchenHeader from "./LalasKitchenHeader";

const PaymentScreen = () => {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [totalAmount, setTotalAmount] = useState(0);
  const [showDateRangeReport, setShowDateRangeReport] = useState(true);
  const [activeComponent, setActiveComponent] = useState("subscription");

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(`${BASE_URL}/createSetupMeals`);
        // Add null checks when filtering test payments
        const filteredData = response.data
          .filter(
            (order) =>
              order?.userdata?.first_name &&
              order.userdata.first_name.toLowerCase() !== "test"
          )
          .sort(
            (a, b) =>
              new Date(b.subscription_details.currentTime) -
              new Date(a.subscription_details.currentTime)
          );
        calculateTotal(filteredData);
        setData(filteredData);
        setLoading(false);
      } catch (err) {
        setError(err);
        setLoading(false);
      }
    };

    fetchData();
  }, []);

  const calculateTotal = (orders) => {
    const total = orders.reduce(
      (acc, order) =>
        acc + parseFloat(order.subscription_details.totalPricePaid),
      0
    );
    setTotalAmount(total.toFixed(2));
  };

  const handleRefund = async (orderId) => {
    const confirmRefund = window.confirm(
      `Are you sure you want to initiate a refund for order ID: ${orderId}?`
    );
    if (!confirmRefund) return;

    try {
      await axios.put(`${BASE_URL}/updateOrderStatus/${orderId}`, {
        orderStatus: 6,
      });
      setData((prevData) =>
        prevData.map((order) =>
          order.orderid === orderId ? { ...order, order_status: 6 } : order
        )
      );
      alert(`Refund initiated for order ID: ${orderId}`);
    } catch (err) {
      alert(`Failed to initiate refund for order ID: ${orderId}`);
    }
  };

  const formatDateTime = (dateTimeString) => {
    const date = new Date(dateTimeString);

    const day = String(date.getDate()).padStart(2, "0");
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const year = date.getFullYear();

    let hours = date.getHours();
    const minutes = String(date.getMinutes()).padStart(2, "0");
    const seconds = String(date.getSeconds()).padStart(2, "0");
    const ampm = hours >= 12 ? "PM" : "AM";

    hours = hours % 12;
    hours = hours ? hours : 12;

    const time = `${hours}:${minutes}:${seconds} ${ampm}`;
    const formattedDate = `${day}/${month}/${year}`;

    return `${formattedDate} ${time}`;
  };

  if (loading) return <div>Loading...</div>;
  if (error) return <div>Error: {error.message}</div>;

  return (
    <div style={{ minHeight: "100vh" }}>
      <LalasKitchenHeader />
      <div className="payment-screen">
        <div style={styles.actionToggle}>
          <div
            style={{
              ...styles.toggleButton,
              ...(activeComponent === "subscription"
                ? styles.activeButton
                : {}),
              borderTopLeftRadius: "8px",
              borderBottomLeftRadius: "8px",
            }}
            onClick={() => setActiveComponent("subscription")}
          >
            Subscription
          </div>
          <div
            style={{
              ...styles.toggleButton,
              ...(activeComponent === "addon" ? styles.activeButton : {}),
              borderTopRightRadius: "8px",
              borderBottomRightRadius: "8px",
            }}
            onClick={() => setActiveComponent("addon")}
          >
            Addon
          </div>
        </div>

        {activeComponent === "subscription" ? (
          <div>
            {/* <button
              onClick={() => setShowDateRangeReport(!showDateRangeReport)}
              style={styles.reportButton}
            >
              {showDateRangeReport ? "Hide Report" : "Show Report"}
            </button> */}

            {showDateRangeReport ? (
              <MonthlyReport data={data} />
            ) : (
              <table style={styles.table}>
                <thead>
                  <tr>
                    <th style={styles.tableHeader}>No</th>
                    <th style={styles.tableHeader}>Date & Time</th>
                    <th style={styles.tableHeader}>First Name</th>
                    <th style={styles.tableHeader}>Phone Number</th>
                    <th style={styles.tableHeader}>Order ID</th>
                    <th style={styles.tableHeader}>Start Date</th>
                    <th style={styles.tableHeader}>End Date</th>
                    <th style={styles.tableHeader}>Transaction ID</th>
                    <th style={styles.tableHeader}>Delivery Charge</th>
                    <th style={styles.tableHeader}>SubTotal</th>
                    <th style={styles.tableHeader}>Discount </th>
                    <th style={styles.tableHeader}>Total Paid </th>
                    <th style={styles.tableHeader}>Refund Request</th>
                  </tr>
                </thead>
                <tbody>
                  {data.map((order, index) => (
                    <tr key={order.subscription_details.startDate}>
                      <td style={styles.tableCell}>{index + 1}</td>
                      <td style={styles.tableCell}>
                        {formatDateTime(order.subscription_details.currentTime)}
                      </td>
                      <td style={styles.tableCell}>
                        {order.userdata.first_name}
                      </td>
                      <td style={styles.tableCell}>
                        {order.userdata.phone_number}
                      </td>
                      <td style={styles.tableCell}>{order.orderid}</td>
                      <td style={styles.tableCell}>
                        {order.subscription_details.startDate}
                      </td>
                      <td style={styles.tableCell}>
                        {order.subscription_details.endDate}
                      </td>
                      <td style={styles.tableCell}>
                        {order.payment_id
                          ? order.payment_id
                          : order.subscription_details.transactionId}
                      </td>
                      <td style={styles.tableCell}>₹{order.deliverycharge}</td>
                      <td style={styles.tableCell}>₹{order.subtotal}</td>
                      <td style={styles.tableCell}>₹{order.discount}</td>
                      <td style={styles.tableCell}>
                        ₹{order.subscription_details.totalPricePaid}
                      </td>
                      <td style={styles.tableCell}>
                        {order.order_status === 2 ? (
                          <span>
                            {order.subscription_status === "Active"
                              ? "Payment complete"
                              : order.subscription_status}
                          </span>
                        ) : order.order_status === 4 ? (
                          <div
                            onClick={() => handleRefund(order.orderid)}
                            style={styles.refundButton}
                          >
                            Order Cancelled by user
                          </div>
                        ) : order.order_status === 6 ? (
                          "Refund Complete"
                        ) : (
                          "N/A"
                        )}
                      </td>
                    </tr>
                  ))}
                  <tr>
                    <td
                      colSpan="8"
                      style={{
                        ...styles.tableCell,
                        textAlign: "right",
                        fontWeight: "bold",
                      }}
                    >
                      Total:
                    </td>
                    <td style={{ ...styles.tableCell, fontWeight: "bold" }}>
                      ₹{totalAmount}/-
                    </td>
                    <td style={styles.tableCell}></td>
                  </tr>
                </tbody>
              </table>
            )}
          </div>
        ) : (
          <div>
            <AddonContainerPayment />
          </div>
        )}
      </div>
    </div>
  );
};

const styles = {
  title: {
    textAlign: "center",
    marginBottom: "20px",
    padding: "20px",
  },
  actionToggle: {
    display: "flex",
    justifyContent: "center",
    marginBottom: "20px",
  },
  toggleButton: {
    padding: "10px 20px",
    border: "1px solid #FAC105",
    background: "white",
    color: "black",
    cursor: "pointer",
  },
  activeButton: {
    background: Colors.LinearGradient,
    color: "black",
  },
  reportButton: {
    padding: "10px 20px",
    fontSize: "16px",
    background: Colors.LinearGradient,
    color: "black",
    border: "none",
    borderRadius: "4px",
    cursor: "pointer",
    marginBottom: "20px",
  },
  table: {
    width: "100%",
    borderCollapse: "collapse",
  },
  tableHeader: {
    border: "1px solid #dddddd",
    padding: "12px",
    backgroundColor: Colors.deepBlue100,
    color: "black",
    textAlign: "left",
  },
  tableCell: {
    border: "1px solid #dddddd",
    padding: "8px",
    textAlign: "left",
  },
  refundButton: {
    backgroundColor: "red",
    color: "white",
    padding: "5px 10px",
    border: "none",
    borderRadius: "5px",
    cursor: "pointer",
    textAlign: "center",
    fontWeight: "bold",
  },
};

export default PaymentScreen;


