import React from 'react';

const LalasKitchenHeader = ({ pageTitle }) => {
  const styles = {
    header: {
      backgroundColor: '#FFF9C4',
      padding: '16px',
      boxShadow: '0 2px 4px rgba(0,0,0,0.1)'
    },
    container: {
      maxWidth: '1200px',
      margin: '0 auto'
    },
    flexContainer: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between'
    },
    logoContainer: {
      display: 'flex',
      alignItems: 'center'
    },
    logo: {
      width: '64px',
      height: '64px',
      marginRight: '16px'
    },
    businessName: {
      fontSize: '24px',
      fontWeight: 'bold',
    },
    address: {
      fontSize: '14px',
      color: '#4B5563'
    },
    contactInfo: {
      textAlign: 'right'
    },
    contactText: {
      fontSize: '14px',
      color: '#4B5563'
    },
    titleContainer: {
      marginTop: '16px',
      borderTop: '1px solid #FDE68A',
      paddingTop: '16px'
    },
    pageTitle: {
      fontSize: '20px',
      fontWeight: '600',
      textAlign: 'center',
      color: '#1E40AF'
    },
    paymentOverview: {
        fontSize: '24px',
        fontWeight: 'bold',
      }
  };

  return (
    <div style={styles.header}>
      <div style={styles.container}>
        <div style={styles.flexContainer}>
          <div style={styles.logoContainer}>
            <img 
              src="https://i.postimg.cc/ZqchknWV/logorazorpay.jpg" 
              alt="Lala's Kitchen Logo" 
              style={styles.logo}
            />
            <div>
              <h1 style={styles.businessName}>Lala's Kitchen</h1>
              <p style={styles.address}>Fssai 1132101000848, Pettah, Trivandrum</p>
            </div>
          </div>
          <div style={styles.contactInfo}>
          {/* <div style={styles.paymentOverview}>Payment Overview</div> */}
          </div>
          <div style={styles.contactInfo}>
            <p style={styles.contactText}>Phone: 9895757524, 7994544423</p>
            <p style={styles.contactText}>Email: lalaskitchen.in@gmail.com</p>
          </div>
        </div>
      </div>
      {pageTitle && (
        <div style={styles.titleContainer}>
          <h2 style={styles.pageTitle}>{pageTitle}</h2>
        </div>
      )}
    </div>
  );
};

export default LalasKitchenHeader;