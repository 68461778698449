import React, { useEffect, useState } from "react";
import { Table, Modal, Form, Button } from "react-bootstrap";
import "../Tables/Addons/List.css";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Colors } from "../../Colors";
import Add from "../../SVG/Add";
import PrimaryButton from "../UTIL/Buttons/PrimaryButton";
import useTable from "../Create/useTable";
import TableFooter from "../Create/TableFooter";
import { BASE_URL } from "../../Service/service";
import { FaEdit, FaTrash } from "react-icons/fa"; // Import React Icons

const menuItemsByType = {
  Breakfast: [
    "4 Idli + Sambar",
    "3 Idiyappam + Veg Kurma",
    "3 Dosa + Kadala Curry",
    "3 Veeshappam + Egg Curry",
    "3 Dosa + Potato Curry",
    "3 Appam + Veg Stew",
    "Puttu + Kadala curry",
    "3 Idiyappam + Egg curry",
    "3 Dosa + Chutney + Vada",
    "Banana Puttu",
    "3 Appam + Egg curry",
    "Puttu & Pazzham",
    "3 Appam + Kadala curry"
  ],
  
  Lunch: {
    "Veg": [
      "Rice + 3 veg + Ozhichucurry",
      "Tomato Rice + Salad + Pickle",
      "Rice + Sambar + 3veg",
      "Rice + 3 veg + Ozhichucurry",
      "Rice + 3veg + Ozhichucurry",
      "Rice + 3 veg + Ozhichucurry",
      "Veg Biriyani"
    ],
    "Non-veg": [
      "Rice + Fish Curry + 2 veg + Ozhichucurry",
      "Tomato Rice + Chicken Fry + Salad &Pickle",
      "Rice + Sambar + 2veg + fish fry",
      "Rice + Fish Curry + 2 veg + Ozhichucurry",
      "Rice + Aviyal + 2veg + fish fry",
      "Rice + Fish Curry + 2 veg + Ozhichucurry",
      "Chicken Biriyani"
    ]
  },
  
  Dinner: [
    "3 Chapati + Dal Curry",
    "3 Idiyappam + Veg Kurma",
    "3 Appam + Greenpeas Curry",
    "3 Veeshappam + Chicken Curry",
    "3 Idiyappam + Veg Curry",
    "3 Dosa + Omelette + Chutney",
    "3 Chapati + Kadala Curry",
    "3 Palappam + Potato curry",
    "3 Chapathi + Dal curry",
    "3 Appam + Chicken curry",
    "3 Veeshappam + Green peas curry",
    "3 Chapathi + Potato curry",
    "3 Dosa + Chammanthi + Omelette",
    "3 Chapati + Potato Curry",
    "3 Appam + Egg curry",
    "3 Palappam + Chicken curry",
    "3 Veeshappam + Veg Kurma",
    "3 Chapati + Green peas curry",
    "Godhuma dosha + White Kadala masala",
    "3 Appam + Veg Kurma"
  ]
};
// Week day ordering for sorting
const weekdayOrder = {
  Monday: 1,
  Tuesday: 2,
  Wednesday: 3,
  Thursday: 4,
  Friday: 5,
  Saturday: 6,
  Sunday: 7,
};

function List() {
  const [page, setPage] = useState(1);
  const [imageModalShow, setImageModalShow] = useState(false);
  const [imageList, setImageList] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [vegType, setVegType] = useState("");
  const [menuType, setMenuType] = useState("");
  const [weekType, setWeekType] = useState("");
  const [menuItems, setMenuItems] = useState([]);
  const [menuData, setMenuData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [images, setImages] = useState([]);
  const [mealPrices, setMealPrices] = useState(null);
  const [vegTypeFilter, setVegTypeFilter] = useState("");
  const [menuTypeFilter, setMenuTypeFilter] = useState("");
  const [filteredMenuData, setFilteredMenuData] = useState([]);
  
  // New states for edit functionality
  const [editMode, setEditMode] = useState(false);
  const [editMenuId, setEditMenuId] = useState(null);
  const [editMealIndex, setEditMealIndex] = useState(null);
  const [editMenuItem, setEditMenuItem] = useState({
    mealType: "",
    imageUrl: "",
    Type: "",
    price: "",
  });

  // Apply filters when filter criteria or data changes
  useEffect(() => {
    if (menuData) {
      applyFilters();
    }
  }, [menuData, vegTypeFilter, menuTypeFilter]);
  
  // Initial data loading
  useEffect(() => {
    fetchMealPrices();
    fetchMenuData();
    fetchImageList();
  }, []);

  // Filter function
  const applyFilters = () => {
    let filtered = [...menuData];

    if (vegTypeFilter) {
      filtered = filtered.filter((menu) => menu.vegtype === vegTypeFilter);
    }

    if (menuTypeFilter) {
      filtered = filtered.filter((menu) => menu.menutype === menuTypeFilter);
    }

    // Sort by weekday order
    filtered.sort((a, b) => {
      return weekdayOrder[a.weektype] - weekdayOrder[b.weektype];
    });

    setFilteredMenuData(filtered);
  };

  const { slice, range } = useTable(filteredMenuData, page, 3);

  // Fetch meal prices from API
  const fetchMealPrices = async () => {
    try {
      const response = await fetch(`${BASE_URL}/meal-prices`);
      const data = await response.json();
      setMealPrices(data);
    } catch (error) {
      console.error("Error fetching meal prices:", error);
      toast.error("Failed to fetch meal prices");
    }
  };

  // Check if all meal types (breakfast, lunch, dinner) are used
  const hasAllMealTypes = () => {
    const mealTypes = new Set(menuItems.map((item) => item.Type));
    return mealTypes.size >= 3;
  };

  // Check if a specific meal type is already used
  const isMealTypeUsed = (type) => {
    return menuItems.some((item) => item.Type === type);
  };

  // Modal control functions
  const handleOpenModal = () => {
    setEditMode(false);
    setShowModal(true);
    // Initialize with one empty meal item
    setMenuItems([
      {
        mealType: "",
        imageUrl: "",
        Type: "",
        price: "",
      },
    ]);
  };

  const handleCloseModal = () => {
    setShowModal(false);
    setEditMode(false);
    setEditMenuId(null);
    setEditMealIndex(null);
    setEditMenuItem({
      mealType: "",
      imageUrl: "",
      Type: "",
      price: "",
    });
  };

  const handleAddMenuItem = () => {
    if (menuItems.length < 3) {
      setMenuItems([
        ...menuItems,
        {
          mealType: "",
          imageUrl: "",
          Type: "",
          price: "",
        },
      ]);
    }
  };

  const handleAddImage = () => {
    setImageModalShow(true);
  };

  // Handle changes to menu item properties
  const handleMenuItemChange = (index, key, value) => {
    const updatedMenuItems = [...menuItems];
    updatedMenuItems[index][key] = value;

    // If the changed field is Type, automatically set the price
    if (key === "Type" && mealPrices) {
      if (value === "Breakfast") {
        updatedMenuItems[index].price = mealPrices.Breakfast;
      } else if (value === "Lunch") {
        // For lunch, set price based on veg/non-veg type
        const isNonVeg = vegType === "Non-veg";
        updatedMenuItems[index].price = isNonVeg
          ? mealPrices.Lunch.NonVeg
          : mealPrices.Lunch.Veg;
      } else if (value === "Dinner") {
        updatedMenuItems[index].price = mealPrices.Dinner;
      }
    }

    setMenuItems(updatedMenuItems);
  };

  // Handle changes to edit menu item
  const handleEditMenuItemChange = (key, value) => {
    setEditMenuItem({
      ...editMenuItem,
      [key]: value,
    });
  };

  // Save the menu to the server
  const handleSaveMenu = () => {
    const menu = {
      VegType: vegType,
      menuType,
      weekType,
      menu: menuItems,
    };

    // Call API to add menu
    fetch(`${BASE_URL}/menu`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(menu),
    })
      .then((response) => response.json())
      .then((data) => {
        console.log("Menu added:", data);
        toast.success("Menu created successfully");
        setVegType("");
        setMenuType("");
        setWeekType("");
        setMenuItems([]);
        handleCloseModal();
        fetchMenuData(); // Refresh the data
      })
      .catch((error) => {
        console.error("Error adding menu:", error);
        toast.error("Error creating menu");
      });
  };

  // Fetch menu data from server
  const fetchMenuData = () => {
    fetch(`${BASE_URL}/menu`)
      .then((response) => response.json())
      .then((data) => {
        setMenuData(data);
        setLoading(false);
      })
      .catch((error) => {
        console.error("Error fetching menu data:", error);
        toast.error("Failed to fetch menu data");
        setLoading(false);
      });
  };

  // Handle menu status change (enable/disable)
  const handleStatusChange = (id, currentStatus) => {
    // Toggle the current status
    const updatedStatus = !currentStatus;

    const requestBody = {
      id: id,
      status: updatedStatus,
    };

    fetch(`${BASE_URL}/menu`, {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(requestBody),
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.success) {
          toast.success(data.message);
          const updatedMenuData = menuData.map((menu) => {
            if (menu.id === id) {
              return {
                ...menu,
                status: updatedStatus,
              };
            } else {
              return menu;
            }
          });

          setMenuData(updatedMenuData);
        } else {
          toast.error(data.message);
        }
      })
      .catch((error) => {
        toast.error("Failed to update status");
        console.error("Error updating status:", error);
      });
  };

  // Handle edit menu item
  const handleEditMenuItem = (menuId, mealIndex, menuItem) => {
    setEditMode(true);
    setEditMenuId(menuId);
    setEditMealIndex(mealIndex);
    setEditMenuItem(menuItem);
    setShowModal(true);
  };

  // Save edited menu item
  const handleSaveEditedMenuItem = () => {
    const requestBody = {
      imageUrl: editMenuItem.imageUrl,
      mealType: editMenuItem.mealType,
      price: editMenuItem.price,
      mealIndex: editMealIndex
    };

    fetch(`${BASE_URL}/menu/${editMenuId}`, {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(requestBody),
    })
      .then((response) => response.json())
      .then((data) => {
        toast.success("Menu item updated successfully");
        
        // Update the local state to reflect changes
        const updatedMenuData = menuData.map((menu) => {
          if (menu.id === editMenuId) {
            const updatedMenu = {...menu};
            updatedMenu.menu[editMealIndex] = {...editMenuItem};
            return updatedMenu;
          }
          return menu;
        });
        
        setMenuData(updatedMenuData);
        handleCloseModal();
      })
      .catch((error) => {
        console.error("Error updating menu item:", error);
        toast.error("Failed to update menu item");
      });
  };

  // Fetch available images
  const fetchImageList = () => {
    fetch(`${BASE_URL}/listImages`)
      .then((response) => response.json())
      .then((data) => {
        const processedImages = data.map((image) => ({
          ...image,
          description: extractDescription(image.image_url),
        }));
        setImageList(data);
        setImages(processedImages);
      })
      .catch((error) => {
        console.error("Error fetching image list:", error);
        toast.error("Failed to fetch image list");
      });
  };

  // Select an image for a menu item
  const handleImageSelect = (imageUrl) => {
    if (editMode) {
      setEditMenuItem({
        ...editMenuItem,
        imageUrl: imageUrl
      });
    } else {
      const updatedMenuItems = [...menuItems];
      updatedMenuItems[menuItems.length - 1].imageUrl = imageUrl;
      setMenuItems(updatedMenuItems);
    }
    setImageModalShow(false);
  };

  // Extract description from image URL
  const extractDescription = (url) => {
    const filename = url.split("/").pop();
    return filename.split(".")[0].replace(/_/g, " ");
  };

  // Delete a menu
  const handleDelete = (id) => {
    // Show a confirmation dialog
    if (window.confirm("Are you sure you want to delete this menu?")) {
      fetch(`${BASE_URL}/menu/${id}`, {
        method: "DELETE",
      })
        .then((response) => {
          if (!response.ok) {
            throw new Error("Network response was not ok");
          }
          return response.json();
        })
        .then((data) => {
          toast.success("Menu deleted successfully");
          // Remove the deleted menu from the state
          setMenuData(menuData.filter((menu) => menu.id !== id));
        })
        .catch((error) => {
          console.error("Error deleting menu:", error);
          toast.error("Failed to delete menu");
        });
    }
  };

  // Get meal options based on Type and vegType
  const getMealOptions = (menuItemType, currentVegType) => {
    if (menuItemType === "Breakfast") {
      return menuItemsByType.Breakfast;
    } else if (menuItemType === "Lunch") {
      return currentVegType === "Non-veg" 
        ? menuItemsByType.Lunch["Non-veg"] 
        : menuItemsByType.Lunch["Veg"];
    } else if (menuItemType === "Dinner") {
      return menuItemsByType.Dinner;
    }
    return [];
  };

  // Styles for table elements
  const headerStyle = { backgroundColor: '#f2f2f2', fontWeight: 'bold' };
  const typeContainerStyle = { 
    display: 'flex', 
    margin: '10px 0', 
    border: '1px solid #ddd', 
    borderRadius: '5px',
    position: 'relative' 
  };
  const imageContainerStyle = { 
    width: '80px', 
    height: '80px', 
    padding: '5px' 
  };
  const imageStyle = { 
    width: '100%', 
    height: '100%', 
    objectFit: 'cover', 
    borderRadius: '5px' 
  };
  const typeDetailsStyle = { 
    flex: 1, 
    padding: '5px' 
  };
  const lineStyle = { 
    margin: '2px 0' 
  };
  const labelStyle1 = { 
    fontWeight: 'bold', 
    marginRight: '5px' 
  };
  const editIconStyle = {
    position: 'absolute',
    top: '5px',
    right: '5px',
    cursor: 'pointer',
    color: '#007bff'
  };

  return (
    <div style={{ flex: 1, minHeight: "100vh" }}>
      <div style={{ margin: "24px" }}>
        <h1
          style={{
            margin: "24px",
            display: "flex",
            justifyContent: "center",
          }}
        >
          Weekly Menu
        </h1>
        <ToastContainer />

        {/* Modal for adding/editing menu */}
        <Modal show={showModal} onHide={handleCloseModal}>
          <Modal.Header closeButton>
            <Modal.Title>{editMode ? "Edit Menu Item" : "Add Menu"}</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            {editMode ? (
              <Form>
                {/* Edit menu item form */}
                <Form.Group controlId="editMealType">
                  <Form.Label>Meal Name</Form.Label>
                  <Form.Control
                    type="text"
                    value={editMenuItem.mealType}
                    onChange={(e) => handleEditMenuItemChange("mealType", e.target.value)}
                  />
                </Form.Group>

                <Form.Group controlId="editImageUrl">
                  <Form.Label>Image URL</Form.Label>
                  <Form.Control
                    type="text"
                    value={editMenuItem.imageUrl}
                    onChange={(e) => handleEditMenuItemChange("imageUrl", e.target.value)}
                  />
                  <Button
                    variant="primary"
                    onClick={handleAddImage}
                    style={{ marginTop: "8px" }}
                  >
                    Upload image
                  </Button>
                </Form.Group>

                <Form.Group controlId="editPrice">
                  <Form.Label>Price</Form.Label>
                  <Form.Control
                    type="text"
                    value={editMenuItem.price}
                    onChange={(e) => handleEditMenuItemChange("price", e.target.value)}
                  />
                </Form.Group>
              </Form>
            ) : (
              <Form>
                <Form.Group controlId="vegType">
                  <Form.Label>Veg Type</Form.Label>
                  <Form.Control
                    as="select"
                    value={vegType}
                    onChange={(e) => setVegType(e.target.value)}
                  >
                    <option value="">Select</option>
                    <option value="Veg">Veg</option>
                    <option value="Non-veg">Non-Veg</option>
                  </Form.Control>
                </Form.Group>
                
                <Form.Group controlId="menuType">
                  <Form.Label>Menu Type</Form.Label>
                  <Form.Control
                    as="select"
                    value={menuType}
                    onChange={(e) => setMenuType(e.target.value)}
                  >
                    <option value="">Select</option>
                    <option value="Regular">Regular</option>
                    <option value="Basic">Basic</option>
                    <option value="Healthy">Healthy</option>
                  </Form.Control>
                </Form.Group>
                
                <Form.Group controlId="weekType">
                  <Form.Label>Week Type</Form.Label>
                  <Form.Control
                    as="select"
                    value={weekType}
                    onChange={(e) => setWeekType(e.target.value)}
                  >
                    <option value="">Select</option>
                    <option value="Monday">Monday</option>
                    <option value="Tuesday">Tuesday</option>
                    <option value="Wednesday">Wednesday</option>
                    <option value="Thursday">Thursday</option>
                    <option value="Friday">Friday</option>
                    <option value="Saturday">Saturday</option>
                    <option value="Sunday">Sunday</option>
                  </Form.Control>
                </Form.Group>
                
                {menuItems.map((menuItem, index) => (
                  <div key={index}>
                    <Form.Group controlId={`Type-${index}`}>
                      <Form.Label style={{ fontWeight: 600, color: "red" }}>
                        {menuItem.Type || "Select Meal Type"}
                      </Form.Label>
                      <Form.Control
                        as="select"
                        value={menuItem.Type}
                        onChange={(e) =>
                          handleMenuItemChange(index, "Type", e.target.value)
                        }
                      >
                        <option value="">Select</option>
                        {!isMealTypeUsed("Breakfast") && (
                          <option value="Breakfast">Breakfast</option>
                        )}
                        {!isMealTypeUsed("Lunch") && (
                          <option value="Lunch">Lunch</option>
                        )}
                        {!isMealTypeUsed("Dinner") && (
                          <option value="Dinner">Dinner</option>
                        )}
                      </Form.Control>
                    </Form.Group>

                    {/* Meal name selection - using the centralized data structure */}
                    <Form.Group controlId={`mealType-${index}`}>
                      <Form.Label>Meal Name</Form.Label>
                      <Form.Control
                        as="select"
                        value={menuItem.mealType}
                        onChange={(e) =>
                          handleMenuItemChange(
                            index,
                            "mealType",
                            e.target.value
                          )
                        }
                        disabled={!menuItem.Type}
                      >
                        <option value="">Select</option>
                        {menuItem.Type && getMealOptions(menuItem.Type, vegType).map((option, idx) => (
                          <option key={idx} value={option}>
                            {option}
                          </option>
                        ))}
                      </Form.Control>
                    </Form.Group>

                    {/* Image URL */}
                    <Form.Group controlId={`imageUrl-${index}`}>
                      <Form.Label>Image URL</Form.Label>
                      <Form.Control
                        type="text"
                        value={menuItem.imageUrl}
                        onChange={(e) =>
                          handleMenuItemChange(
                            index,
                            "imageUrl",
                            e.target.value
                          )
                        }
                      />
                      <Button
                        variant="primary"
                        onClick={handleAddImage}
                        style={{ marginTop: "8px" }}
                      >
                        Upload image
                      </Button>
                    </Form.Group>
                    
                    {/* Price (read-only) */}
                    <Form.Group controlId={`price-${index}`}>
                      <Form.Label>Price</Form.Label>
                      <Form.Control
                        type="text"
                        value={menuItem.price}
                        readOnly
                        style={{ backgroundColor: "#f8f9fa" }}
                      />
                    </Form.Group>
                  </div>
                ))}
                
                {/* Add meal type button */}
                {!hasAllMealTypes() && (
                  <Button
                    variant="primary"
                    onClick={handleAddMenuItem}
                    style={{ marginTop: "8px" }}
                  >
                    Add Meal Type
                  </Button>
                )}
              </Form>
            )}
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleCloseModal}>
              Close
            </Button>
            {editMode ? (
              <Button 
                variant="primary" 
                onClick={handleSaveEditedMenuItem}
                disabled={!editMenuItem.mealType || !editMenuItem.imageUrl}
              >
                Save Changes
              </Button>
            ) : (
              <Button 
                variant="primary" 
                onClick={handleSaveMenu}
                disabled={!vegType || !menuType || !weekType || menuItems.some(item => !item.Type || !item.mealType)}
              >
                Save
              </Button>
            )}
          </Modal.Footer>
        </Modal>

        {/* Image selection modal */}
        <Modal
          show={imageModalShow}
          onHide={() => setImageModalShow(false)}
          fullscreen={true}
        >
          <Modal.Header closeButton>
            <Modal.Title>Select Image</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                flexWrap: "wrap",
                gap: "10px",
                justifyContent: "flex-start",
                padding: "10px",
                width: "100%",
                paddingRight: "200px",
              }}
            >
              {images.map((image) => (
                <div
                  key={image.id}
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    flex: "1 0 auto",
                    minWidth: "150px",
                    maxWidth: "200px",
                    margin: "5px",
                  }}
                >
                  <img
                    src={image.image_url}
                    alt="Image"
                    onClick={() =>
                      handleImageSelect(image.image_url)
                    }
                    style={{
                      width: "100%",
                      height: "150px",
                      cursor: "pointer",
                      objectFit: "cover",
                      borderRadius: "4px",
                    }}
                  />
                  <div
                    style={{
                      marginTop: "8px",
                      textAlign: "center",
                      fontSize: "14px",
                      width: "100%",
                    }}
                  >
                    {image.description}
                  </div>
                </div>
              ))}
            </div>
          </Modal.Body>
        </Modal>

        {/* Add new menu button */}
        <div
          style={{ display: "flex", height: "2.5rem", marginBottom: "1rem" }}
        >
          <div
            style={{
              display: "flex",
              flex: 1,
              justifyContent: "space-between",
            }}
          >
            <PrimaryButton
              text={"Add new"}
              icon={<Add />}
              onClick={handleOpenModal}
            />
          </div>
        </div>
        
        {/* Filters */}
        <div style={{ display: "flex", gap: "20px", marginBottom: "20px" }}>
          <Form.Group style={{ width: "200px" }}>
            <Form.Label>Filter by Veg Type</Form.Label>
            <Form.Control
              as="select"
              value={vegTypeFilter}
              onChange={(e) => setVegTypeFilter(e.target.value)}
            >
              <option value="">All</option>
              <option value="Veg">Veg</option>
              <option value="Non-veg">Non-Veg</option>
            </Form.Control>
          </Form.Group>

          <Form.Group style={{ width: "200px" }}>
            <Form.Label>Filter by Menu Type</Form.Label>
            <Form.Control
              as="select"
              value={menuTypeFilter}
              onChange={(e) => setMenuTypeFilter(e.target.value)}
            >
              <option value="">All</option>
              <option value="Regular">Regular</option>
              <option value="Basic">Basic</option>
              <option value="Healthy">Healthy</option>
            </Form.Control>
          </Form.Group>
        </div>
        
        {/* Menu Table */}
        <div>
          <Table striped bordered hover>
            <thead>
              <tr>
                <th style={headerStyle}>ID</th>
                <th style={headerStyle}>Veg Type</th>
                <th style={headerStyle}>Menu Type</th>
                <th style={headerStyle}>Week Type</th>
                <th style={headerStyle}>Menu</th>
                <th style={headerStyle}>Status</th>
                <th style={headerStyle}>Delete</th>
              </tr>
            </thead>
            <tbody>
              {loading ? (
                <tr>
                  <td colSpan="7">Loading...</td>
                </tr>
              ) : filteredMenuData.length === 0 ? (
                <tr>
                  <td colSpan="7" style={{ textAlign: "center" }}>No menu items found</td>
                </tr>
              ) : (
                filteredMenuData.map((menu, index) => (
                  <tr key={menu.id}>
                    <td>{index + 1}</td>
                    <td>{menu.vegtype}</td>
                    <td>{menu.menutype}</td>
                    <td>{menu.weektype}</td>
                    <td>
                      <ul style={{ listStyleType: "none", padding: 0 }}>
                        {menu.menu.map((item, idx) => (
                          <div key={idx} style={typeContainerStyle}>
                            <div style={imageContainerStyle}>
                              <img
                                src={item.imageUrl}
                                alt="Food"
                                style={imageStyle}
                              />
                            </div>
                            <div style={typeDetailsStyle}>
                              <div style={lineStyle}>
                                <span style={labelStyle1}>{item.Type}</span>
                              </div>
                              <div style={lineStyle}>
                                <span style={labelStyle1}>Meal Type:</span>
                                {item.mealType}
                              </div>
                              <div style={lineStyle}>
                                <span style={labelStyle1}>Price:</span>
                                {item.price}
                              </div>
                            </div>
                            {/* Edit icon */}
                            <FaEdit 
                              style={editIconStyle} 
                              onClick={() => handleEditMenuItem(menu.id, idx, item)}
                              title="Edit this menu item"
                            />
                          </div>
                        ))}
                      </ul>
                    </td>

                    <td valign="middle">
                      <button
                        style={{
                          backgroundColor: menu.status ? "green" : "red",
                          color: "white",
                          border: "none",
                          borderRadius: "4px",
                          padding: "8px 12px",
                          cursor: "pointer",
                        }}
                        onClick={() => handleStatusChange(menu.id, menu.status)}
                      >
                        {menu.status ? "Enable" : "Disable"}
                      </button>
                    </td>
                    <td valign="middle">
                      <button
                        style={{
                          backgroundColor: "red",
                          color: "white",
                          border: "none",
                          borderRadius: "4px",
                          padding: "8px 12px",
                          cursor: "pointer",
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                          gap: "5px"
                        }}
                        onClick={() => handleDelete(menu.id)}
                      >
                        <FaTrash /> Delete
                      </button>
                    </td>
                  </tr>
                ))
              )}
            </tbody>
          </Table>
        </div>

        {/* Pagination */}
        <TableFooter
          range={range}
          slice={slice}
          setPage={setPage}
          page={page}
        />
      </div>
    </div>
  );
}

export default List;