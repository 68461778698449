
import React, { useState, useEffect, useRef } from "react";
import {
  Navbar,
  Nav,
  Container,
  NavDropdown,
  Modal,
  Form,
  Button,
  Alert,
} from "react-bootstrap";
import { useLocation, useNavigate } from "react-router-dom";
import { HashLink } from "react-router-hash-link";
import { Colors } from "./Colors";

const NavigationBar = () => {
  const [activeLink, setActiveLink] = useState("");
  const [showDropdown, setShowDropdown] = useState(false);
  const [showLoginModal, setShowLoginModal] = useState(false);
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [loginError, setLoginError] = useState("");
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const dropdownRef = useRef(null);
  const location = useLocation();
  const navigate = useNavigate();

  const hardcodedUsername = "admin";
  const hardcodedPassword = "password";

  useEffect(() => {
    const loggedInUser = localStorage.getItem("user");
    if (loggedInUser) {
      setIsLoggedIn(true);
      if (location.pathname === "/" || location.pathname === "/privacy" || location.pathname === "/terms" || location.pathname === "/deleteform") {
        navigate("/todays_order");
      }
    } else if (location.pathname !== "/" && location.pathname !== "/privacy" && location.pathname !== "/terms" && location.pathname !== "/deleteform") {
      navigate("/");
    }
    setActiveLink(location.pathname);
  }, [location, navigate]);



  const navItems = [
    { href: "/todays_order", label: "Today's Order" },
    { href: "/activeorders", label: "Subscriptions" },
    { href: "/addonmain", label: "Addons" },
  ];

  const dropdownItems = [
    { href: "/userdata", label: "User Details" },
    { href: "/addons/list", label: "Addon Items" },
    { href: "/weeklymenu", label: "Weekly Menu" },
    { href: "/savedimages", label: "Saved Images" },
    { href: "/payment", label: "Payment Overview" },
    { href: "/razorpay", label: "Payments" },
    { href: "/rating", label: "Rating" },
    { href: "/playstoreupdate", label: "Play Store Update" },
    // { href: "/pausesubscription", label: "Pause Subscription" }, 
    { href: "/systeminfo", label: "System Info" },
    { href: "/deliveryuser", label: "Delivery User Details" },
    { href: "/service", label: "Service " },
    // { href: "/chat", label: "Chat " },
  ];

  const handleMouseEnter = () => {
    if (window.innerWidth > 992) {
      setShowDropdown(true);
    }
  };

  const handleMouseLeave = () => {
    if (window.innerWidth > 992) {
      setShowDropdown(false);
    }
  };

  const handleToggle = () => {
    if (window.innerWidth <= 992) {
      setShowDropdown(!showDropdown);
    }
  };

  const isRootPath = ["/", "/privacy", "/terms", "/deleteform"].includes(
    location.pathname
  );

  const handleLoginClick = () => {
    setShowLoginModal(true);
  };

  const handleCloseModal = () => {
    setShowLoginModal(false);
    setUsername("");
    setPassword("");
    setLoginError("");
  };

  const handleLogin = (e) => {
    e.preventDefault();
    if (username === hardcodedUsername && password === hardcodedPassword) {
      handleCloseModal();
      setIsLoggedIn(true);
      localStorage.setItem("user", JSON.stringify({ username }));
      navigate("/todays_order");
    } else {
      setLoginError("Invalid username or password");
    }
  };
  const handleLogout = () => {
    setIsLoggedIn(false);
    localStorage.removeItem("user");
    navigate("/");
  };
  useEffect(() => {
    setActiveLink(location.pathname);
    const loggedInUser = localStorage.getItem("user");
    if (loggedInUser) {
      setIsLoggedIn(true);
    }
  }, [location]);
  return (
    <>
      <Navbar
        style={{ background: Colors.LinearGradient }}
        expand="lg"
        className="py-3"
      >
        <Container fluid>
          <Navbar.Brand
            href="/todays_order"
            className="fw-bold fs-4 d-flex align-items-center"
            style={{ marginLeft: "24px" }}
          >
            My Lalas
          </Navbar.Brand>
          <Navbar.Toggle aria-controls="basic-navbar-nav" />
          <Navbar.Collapse id="basic-navbar-nav">
            <Nav className="ms-auto align-items-lg-center">
              {isRootPath ? (
                <>
                  <HashLink
                    smooth
                    to="/#menu"
                    style={{ textDecoration: "none" }}
                  >
                    <li className="nav-item">
                      <a className="nav-link" href="#">
                        <span style={{ fontWeight: "900", color: "#2F2C5D" }}>
                          Menu
                        </span>
                      </a>
                    </li>
                  </HashLink>
                  <HashLink
                    smooth
                    to="/#apps"
                    style={{ textDecoration: "none" }}
                  >
                    <li className="nav-item">
                      <a className="nav-link" href="#">
                        <span style={{ fontWeight: "900", color: "#2F2C5D" }}>
                          App
                        </span>
                      </a>
                    </li>
                  </HashLink>
                  <HashLink
                    smooth
                    to="/#contact"
                    style={{ textDecoration: "none" }}
                  >
                    <li className="nav-item">
                      <a className="nav-link" href="#">
                        <span style={{ fontWeight: "900", color: "#2F2C5D" }}>
                          Contact
                        </span>
                      </a>
                    </li>
                  </HashLink>
                </>
              ) : (
                <>
                  {navItems.map((item) => (
                    <Nav.Link
                      key={item.href}
                      href={item.href}
                      className={`mx-1 fw-semibold ${
                        activeLink === item.href ? "active" : ""
                      }`}
                      style={{
                        borderBottom:
                          activeLink === item.href ? "3px solid #000" : "none",
                        paddingBottom: "5px",
                      }}
                    >
                      {item.label}
                    </Nav.Link>
                  ))}
                  <div
                    ref={dropdownRef}
                    onMouseEnter={handleMouseEnter}
                    onMouseLeave={handleMouseLeave}
                  >
                    <NavDropdown
                      title="More"
                      id="basic-nav-dropdown"
                      className="mx-2 fw-semibold"
                      show={showDropdown}
                      onClick={handleToggle}
                    >
                      {dropdownItems.map((item) => (
                        <NavDropdown.Item
                          key={item.href}
                          href={item.href}
                          active={activeLink === item.href}
                          style={{
                            backgroundColor:
                              activeLink === item.href
                                ? "#FAC105"
                                : "transparent",
                            color: activeLink === item.href ? "#000" : "#000",
                          }}
                        >
                          {item.label}
                        </NavDropdown.Item>
                      ))}
                    </NavDropdown>
                  </div>
                </>
              )}
              <Nav.Link
                onClick={
                  isLoggedIn
                    ? handleLogout
                    : isRootPath
                    ? handleLoginClick
                    : () => {}
                }
                href={isLoggedIn ? "#" : isRootPath ? "#" : "/"}
                className={`mx-2 fw-bold ${activeLink === "/" ? "active" : ""}`}
                style={{
                  borderBottom: activeLink === "/" ? "3px solid #000" : "none",
                  paddingBottom: "5px",
                }}
              >
                {isLoggedIn ? "Log Out" : isRootPath ? "Login" : "Log Out"}
              </Nav.Link>
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>

      <Modal show={showLoginModal} onHide={handleCloseModal} centered>
        <Modal.Header closeButton style={{ background: Colors.LinearGradient }}>
          <Modal.Title className="text-black">Login</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form onSubmit={handleLogin}>
            <Form.Group className="mb-3" controlId="formBasicEmail">
              <Form.Label>Username</Form.Label>
              <Form.Control
                type="text"
                placeholder="Enter username"
                value={username}
                onChange={(e) => setUsername(e.target.value)}
                required
              />
            </Form.Group>

            <Form.Group className="mb-3" controlId="formBasicPassword">
              <Form.Label>Password</Form.Label>
              <Form.Control
                type="password"
                placeholder="Password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                required
              />
            </Form.Group>

            {loginError && <Alert variant="danger">{loginError}</Alert>}

            <Button
              variant="primary"
              type="submit"
              className="w-100"
              style={{
                background: Colors.LinearGradient,
                border: "none",
                color: "black",
              }}
            >
              Sign In
            </Button>
          </Form>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default NavigationBar;
